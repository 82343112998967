<template>
  <v-app class="bg-login">
    <app-loader v-if="loader"></app-loader>
    <v-main>
      <v-container fluid fill-height>
        <v-flex xs12 md10 lg8 xl6 class="mx-auto card-wrapper">
          <v-card rounded="lg" style="z-index: 100">
            <div
              class="d-flex justify-space-betweeen flex-column flex-md-row"
              style="min-height: 650px"
            >
              <!-- Inicia contenido lado izquierdo -->
              <div
                class="px-4 py-4 py-my-0 px-sm-8 px-md-16 flex-shrink-1 flex-grow-1 d-flex align-center"
              >
                <div class="flex-grow-1 flex-shrink-1" style="max-width: 100%">
                  <v-img
                    contain
                    class="mx-auto d-flex mb-2 logo__img"
                    src="@/assets/img/comprasal_login.png"
                    max-width="350"
                  />
                  <p class="text-h5 text-center pt-5 primary--text">
                    Iniciar sesión
                  </p>

                  <v-form @submit.prevent="login()">
                    <v-text-field
                      ref="emailInput"
                      append-icon="mdi-account"
                      name="login"
                      label="Correo Electrónico *"
                      type="text"
                      @blur="$v.form.email.$touch()"
                      v-model="form.email"
                      :error-messages="emailErrors"
                    >
                    </v-text-field>
                    <div>
                      <v-text-field
                        id="password"
                        name="password"
                        v-model="form.password"
                        label="Contraseña *"
                        :type="showPassword ? 'text' : 'password'"
                        @blur="$v.form.password.$touch()"
                        :error-messages="passwordErrors"
                      >
                        <template #append>
                          <v-icon
                            v-if="!showPassword"
                            @click="showPassword = !showPassword"
                            :color="passwordErrors.length > 0 ? 'red' : ''"
                          >
                            mdi-eye
                          </v-icon>
                          <v-icon
                            v-else
                            @click="showPassword = !showPassword"
                            :color="passwordErrors.length > 0 ? 'red' : ''"
                            >mdi-eye-off
                          </v-icon>
                        </template>
                      </v-text-field>
                      <template class="text-h5 text-center pt-5 primary--text">
                        <p class="text-h5 text-center pt-5 primary--text">
                          <router-link
                            style="
                              font-size: 1.25rem;
                              font-family: 'MuseoSans' !important;
                              color: #2792fd;
                              font-weight: bold;
                            "
                            color="primary"
                            to="/recuperar-password"
                          >
                            Restablecer contraseña.
                          </router-link>
                        </p>
                      </template>
                    </div>

                    <div class="text-center pb-md-6">
                      <v-btn
                        class="mt-10"
                        color="primary white--text px-sm-8"
                        :loading="request.isLoading"
                        type="submit"
                      >
                        iniciar sesión
                      </v-btn>
                    </div>
                  </v-form>
                </div>
              </div>
              <!-- Finaliza contenido lado izquierdo -->

              <v-divider vertical class="flex-shrink-0" />
              <v-divider class="d-md-none" />

              <!-- Inicia contenido lado derecho -->
              <div
                class="login-right-content px-4 py-4 pt-md-0 flex-grow-1"
                style="flex-basis: 225px !important"
              >
                <div style="max-width: 100%">
                  <div
                    class="option-name d-flex justify-center align-center mt-md-4"
                    style="gap: 8px"
                  >
                    <v-icon class="option-icon"
                      >mdi-format-list-bulleted</v-icon
                    >
                    <h5 class="text-subtitle-1 font-weight-bold">
                      Regístrate como
                    </h5>
                  </div>

                  <div
                    class="mt-4 d-flex flex-wrap align-center"
                    style="gap: 16px"
                  >
                    <v-btn
                      color="secondary"
                      class="signup-option text-no-style flex-grow-1 flex-shrink-1"
                      style="min-width: 200"
                      to="/register"
                    >
                      <span>Proveedor</span>
                    </v-btn>
                    <v-btn
                      color="secondary"
                      class="signup-option text-no-style flex-grow-1 flex-shrink-1"
                      style="min-width: 200px"
                      to="/registro-funcionario"
                    >
                      <span>Titular de institución</span>
                    </v-btn>
                  </div>

                  <div
                    class="option-name d-flex justify-center align-center mt-8"
                    style="gap: 8px"
                  >
                    <v-icon class="option-icon">mdi-link</v-icon>
                    <h5 class="text-subtitle-1 font-weight-bold">
                      Enlaces adicionales
                    </h5>
                  </div>

                  <div class="my-4 d-flex flex-wrap" style="gap: 8px">
                    <v-btn
                      text
                      href="https://unac.gob.sv/"
                      class="external-link flex-grow-1 flex-shrink-1"
                    >
                      Procesos LACAP
                    </v-btn>

                    <v-btn
                      text
                      to="/calculadora-razonabilidad-precios"
                      class="external-link flex-grow-1 flex-shrink-1"
                    >
                      Calculadora razonabilidad de precios
                    </v-btn>

                    <v-btn
                      text
                      href="https://www.contratacionespublicasca.sieca.int/es#gsc.tab=0"
                      class="external-link flex-grow-1 flex-shrink-1"
                    >
                      Punto de Acceso Regional de Contratación Pública
                      Centroamericana
                    </v-btn>

                    <v-btn
                      text
                      href="https://trade.ec.europa.eu/access-to-markets/en/content/eu-central-america-association-agreement#toc_8"
                      class="external-link flex-grow-1 flex-shrink-1"
                    >
                      Contrataciones públicas de la Unión Europea (Market
                      Access)
                    </v-btn>

                    <!-- <v-btn text to="/login-procesos-compra" class="external-link flex-grow-1 flex-shrink-1 text-truncate">
                      Procesos de compra
                    </v-btn> -->

                    <!-- <v-btn text to="/procesos-publicos" class="external-link flex-grow-1 flex-shrink-1 text-truncate">
                        Procesos de Compra
                    </v-btn>

                    <v-btn text to="/login-list-proveedores" class="external-link flex-grow-1 flex-shrink-1 text-truncate">
                        Listado de Proveedores
                    </v-btn>

                    <v-btn text to="/login-list-sancion-proveedores" class="external-link flex-grow-1 flex-shrink-1 text-truncate">
                        Sanciones
                    </v-btn> -->

                  </div>
                </div>
              </div>
              <!-- Finaliza contenido lado derecho -->
            </div>
          </v-card>
        </v-flex>
      </v-container>
    </v-main>
    <v-spacer class="my-4"></v-spacer>

    <v-footer
      class="login-footer primary py-3 d-flex flex-wrap justify-center align-center"
      style="z-index: 100; gap: 32px"
    >
      <v-img
        class="flex-shrink-1"
        src="@/assets/img/goes_hrz_blanco.png"
        max-width="300px"
      />
      <div
        class="login-footer-institucion d-flex align-center"
        style="gap: 16px"
      >
        <v-img
          contain
          class="d-flex logo__img"
          src="@/assets/img/logo-dinac-simple.png"
          max-width="125"
        />
        <a href="https://x.com/dinacsv" target="_blank">
          <v-icon color="#FFF !important" x-large>mdi-twitter</v-icon>
        </a>
        <a href="https://dinac.gob.sv" target="_blank">
          <v-icon color="#FFF !important" x-large>mdi-web</v-icon>
        </a>
      </div>
    </v-footer>
    <Confirm2FaModal
      :isOpen="is2FaConfirmationOpen"
      @on-visibility-change="handleVisibilityChange"
      :email="form.email"
      :servicio="servicio"
      :mensaje="mensaje"
    />
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
  isResponseSuccesful,
} from "@/utils/loadable";
import Confirm2FaModal from "./Confirm2FaModal.vue";
import AppLoader from "@/components/AppLoaderComponent.vue";
import { required, helpers } from "vuelidate/lib/validators";
import { isNil } from "lodash";
import jwtDecode from "jwt-decode";

const email = helpers.regex(
  "email",
  /^[a-zA-Z\d]+[a-zA-Z\d._-]*@([a-zA-Z\d]+(-|.)?){0,3}[a-zA-Z\d]+(\.[a-zA-Z\d]+)?(\.[a-zA-Z\d]{2,})$/
);

import serviciosAuth from "../../services/Auth.services";
import serviciosAutenticacion from "../../services/Autenticacion.services";
import { LocalStorage } from "@/utils/LocalStorage.mjs";

const default2Fa = serviciosAuth.verify2fa;
const app2Fa = serviciosAutenticacion.ingresarTopt;
const default2faMessage =
  "Ingrese el código que ha sido enviado a su correo, por favor";

export default {
  name: "loginView",
  components: {
    AppLoader,
    Confirm2FaModal,
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  data: () => ({
    form: {
      email: null,
      password: null,
    },
    showPassword: false,
    is2FaConfirmationOpen: false,
    request: createLoadable(null),
    servicio: default2Fa,
    mensaje: default2faMessage,
  }),
  methods: {
    ...mapActions(["setAuth"]),
    ...mapMutations(["setToken", "setUserInfo"]),
    ...mapMutations("utils", ["setRutas"]),
    async login() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (
        process.env.VUE_APP_VALIDAR_CORREO_GUBERNAMENTAL === "true" &&
        this.form.email.includes(".gob.sv")
      ) {
        this.pushAppMessage({
          message: "Por favor inicie sesión por medio de ID para funcionarios",
          type: "warning",
        });
        this.$router.push({ name: "identidad-digital" });
        return;
      }

      toggleLoadable(this.request);
      const { data } = await this.services.Auth.login(this.form);
      if (
        data.error &&
        ["Usuario suspendido", "Usuario deshabilitado"].includes(
          data.error.message
        )
      ) {
        this.request.isLoading = false;
        return;
      }
      setLoadableResponse(this.request, data, { showAlertOnSuccess: true });

      if (!isResponseSuccesful(data)) return;

      // Retornamos si no viene data, ya que se debe actualizar la contraseña a través del correo enviado
      if (isNil(data.data)) return;

      // Confirmar 2FA
      if (data.data.should_confirm_2fa) {
        this.is2FaConfirmationOpen = true;
        return;
      }
      if (data.data.login_auth) {
        this.is2FaConfirmationOpen = true;
        this.servicio = app2Fa;
        this.mensaje =
          "Ingrese el código que aparece en su aplicación de google authenticator";
        return;
      }

      // Flujo normal en caso vengan los tokens
      const token = data.data.token;
      const { user } = jwtDecode(token);
      this.setAuth(data.data);

      if (user?.two_factor_status) {
        return this.$router.push({ name: "2fa" });
      }

      if (this.haveRole("ROLE_PROVEEDOR"))
        localStorage.setItem("unidades", "false");

      this.$router.push({ name: "dashboard" });
    },
    handleVisibilityChange(visible) {
      this.is2FaConfirmationOpen = visible;
      if (!visible) {
        this.mensaje = default2faMessage;
        this.servicio = default2Fa;
      }
    },
  },
  computed: {
    ...mapState("utils", ["loader", "menu"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required &&
        errors.push("El correo es necesario para continuar");
      !this.$v.form.email.email &&
        errors.push("Ingresa un correo con formato válido");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required &&
        errors.push("Ingresa una contraseña válida");
      return errors;
    },
  },
  created() {
    this.setRutas([]);
    const tokenExistenteProcesosLibres = LocalStorage.token_procesos_libres;
    localStorage.clear();

    if (tokenExistenteProcesosLibres) {
      LocalStorage.token_procesos_libres = tokenExistenteProcesosLibres;
    }
  },
  mounted() {
    this.$refs.emailInput.focus();
  },
};
</script>
<style scoped>
.external-link {
  font-size: 0.8rem !important;
  text-transform: none;
}

.bg-login {
  background-image: url("../../assets/img/fondo_playa.jpg") !important;
  background-size: cover !important;
  background-position: center top !important;
  background-repeat: no-repeat !important;
  position: relative;
}

.login-footer {
  position: relative;
  min-height: 80px;
}

.bg-login::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  top: 0;
}

:deep(.card-wrapper) {
  max-width: 100%;
}

:deep(.login-right-content) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 0px;
  background-color: #ffffff !important;
}

:deep(.external-link) {
  max-width: 100%;
  width: 100%;
  height: unset !important;
  min-height: unset !important;
  padding: 8px 16px !important;
  justify-content: flex-start;
  text-align: left;
}

:deep(.external-link > span) {
  max-width: 100%;
  word-break: break-word;
  white-space: normal;
  text-align: left !important;
  width: 100%;
}

@media (min-width: 960px) {
  :deep(.login-right-content) {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    overflow: hidden !important;
    background-color: #313945 !important;
  }

  .option-name,
  .external-link,
  .option-icon,
  .link-icon {
    color: #fff !important;
  }

  .signup-option {
    background-color: #f5f5f5 !important;
  }

  :deep(.signup-option .v-btn__content) {
    color: #313945 !important;
  }

  :deep(.card-wrapper) {
    max-width: 1200px;
  }

  :deep(.login-footer .login-footer-institucion) {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
